.alert {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}